/* ######################################### */
/* FONTS */
/* ######################################### */
@font-face {
  font-family: "Gotham Black";
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Black"),
    url("../fonts/Gotham Black Regular.woff") format("woff");
}

/* ######################################### */
/*GENERAL */
/* ######################################### */
.color1 {
  color: #1795a1;
}

.color2 {
  color: #eb6009;
}

.bg1 {
  background-color: #292927;
  padding: 20px 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* ######################################### */
/* HEADER */
/* ######################################### */
.header .link {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.header .link:hover {
  text-decoration: none;
  color: #1795a1;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content a:hover {
  color: #1795a1 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #292927;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  font-size: 1rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}


/* ######################################### */
/* FOOTER */
/* ######################################### */
.footer-title {
  color: #fff;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: bolder;
}

.footer-title:hover {
  color: #eb6009;
  text-decoration: none;
  font-size: 0.85rem;
}


/* ######################################### */
/* LOGIN */
/* ######################################### */
.login {
  height: 49vh;
}

/* ######################################### */
/* HOME */
/* ######################################### */
.home .cadre {
  position: absolute;
  top: -31px;
}

.home .programme {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home .programme .c-programme {
  border: solid 10px #000;
  padding: 35px;
  background-color: #fff;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 115px;
}

.home .programme .c-programme .programme-titre {
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  background: rgb(232, 75, 15);
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 10px 40px 10px 40px;
  /* width: 280px; */
  margin-top: -7px;
  text-align: center;
}

.home .programme .c-programme .programme-soustitre {
  font-size: 1.6rem;
  font-weight: 900;
  background-color: #e0e0e0;
  color: #000;
  padding: 10px 40px 10px 40px;
  /* width: 250px; */
  text-align: center;
  margin-top: -9px;
}

.home .programme .titre {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin-top: 80px;
}

.home .c-programme .prog {
  font-size: 1.4rem;
  color: black;
  text-align: center;
  /* margin-bottom: 10px; */
  /* padding-top: 25px;
	padding-bottom: 10px; */
}

.home .c-programme .prog p {
  padding-top: 20px;
}

.home .c-programme .prog .horaire {
  color: #1795a1;
  font-weight: 900;
}

.home .c-programme .prog .horaire-soustitre {
  font-size: 1rem;
}

.home .programme .sep {
  width: 85px;
  height: 4px;
  background-color: #000;
  margin: 25px 0;
}

.home .programme .sep2 {
  width: 30px;
  height: 1px;
  background-color: #1795a1;
  margin-top: 20px;
}

.background-journee .card {
  --bs-card-border-width: 5px !important;
  --bs-card-border-color: #000 !important;
  --bs-card-border-radius: 0;
}

.background-journee .programme-titre2 {
  font-size: 1.4rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  padding: 10px 20px 10px 20px;
  width: 260px;
  text-align: center;
}

.background-journee .blocs {
  margin-top: 62px;
  margin-bottom: 62px;
  display: flex;
  justify-content: center;
}

.background-journee .bloc {
  position: relative;
  border: solid 6px #000;
  width: 320px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px grey;
  background-color: #fff;
  height: 360px;
}

.background-journee .t-bloc {
  text-align: center;
  /* position:absolute; */
  /* top:-20px; */
  width: 100%;
  margin-top: -70px;
  margin-bottom: 20px;
}

.background-journee .t-bloc span {
  padding: 5px 12px;
  display: inline-block;
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  background: rgb(232, 75, 15);
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
}

.background-journee .texte {
  font-size: 1rem;
  /* margin-top: 10px; */
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.background-journee .bloc ul {
  text-align: center;
  margin-right: 20px;
}

.background-journee .bloc ul li {
  padding-bottom: 18px;
}

.background-contact {
  width: 100%;
  background-color: #292927;
  position: relative;
  box-shadow: 0px 0px 10px 0px #000;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-top: 30px; */
}

.container-contact {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -20px;
}

.background-contact .programme-titre2 {
  font-size: 1.9rem;
  font-weight: 900;
  text-transform: uppercase;
  background: rgb(232, 75, 15);
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 10px 40px 10px 40px;
  text-align: center;
  margin-top: -5px;
}

.background-contact .form-partenaire {
  padding: 0 20px 80px;
  overflow-x: hidden;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.background-contact .t-form {
  text-align: center;
  max-width: 1044px;
  font-size: 26px;
  color: #fff;
  font-weight: bold;
  /* padding:0 22px; */
  margin-top: 25px;
  margin-bottom: 25px;
  box-sizing: border-box;
}

.background-contact .form-partenaire .champs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 7px;
}

.background-contact .form-partenaire .champs input {
  width: 330px;
  height: 40px;
  background-color: #fff;
  border: 0 none;
  padding: 0 30px;
  box-sizing: border-box;
  font-size: 18px;
  color: #000;
  margin: 22px;
}

.background-contact .form-partenaire .submit {
  margin-top: 50px;
  text-align: center;
}

.background-contact .form-partenaire .submit button {
  display: inline-block;
  font-size: 24px;
  color: #ffffff;
  background: rgb(232, 75, 15);
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  border: 0 none;
  cursor: pointer;
  padding: 3px 42px;
}

/* ######################################### */
/* OFFRE */
/* ######################################### */
.offres {
  padding: 10px 0 30px 0;
  border-bottom: solid 8px #eb6009;
}

.offres-liste {
  background-color: #ebebeb;
  padding-bottom: 25px;
}

.offres-liste .cadre {
  background-color: #fff;
  border: solid 2px #1795a1;
  padding: 20px 10px 10px 10px;
  /* margin-top: 10px; */
  margin-bottom: 15px;
}

.button-offre {
  border: solid 2px #1795a1;
  border-radius: 30px;
  color: #1795a1;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-offre:hover {
  border: solid 2px #1795a1;
  border-radius: 30px;
  color: #eb6009;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.offres-bottom {
  background-color: #dbdbdb;
  padding: 40px 0;
}

.button-offre-inscription {
  border: solid 2px #1795a1;
  background-color: #1795a1;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  padding: 6px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-offre-inscription:hover {
  border: solid 2px #1795a1;
  background-color: #1795a1;
  border-radius: 30px;
  color: #eb6009;
  font-weight: bold;
  padding: 6px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.logo-entreprise {
  text-align: left;
}

.button-offre-leposte {
  border: solid 2px #eb6009;
  background-color: #eb6009;
  color: #fff;
  font-weight: bold;
  padding: 3px 20px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.button-offre-postuler {
  border: solid 2px #1795a1;
  background-color: #1795a1;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  padding: 6px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
}

.button-offre-postuler:hover {
  border: solid 2px #eb6009;
  background-color: #eb6009;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  padding: 6px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
}

.offre-details {
  background-color: #ebebeb;
  padding-bottom: 55px;
}

.offre-details-padding {
  padding: 15px 135px !important;
}

/* ######################################### */
/* ORGANISATEURS : CFA & LIEUX DE FORMATION */
/* ######################################### */
.cfa-page {
  padding: 165px 0;
}

.cfa-liste {
  padding: 0 0 145px 0;
}

.cfa {
  padding: 0 0 70px 0;
}

.cfa .container-titre {
  display: flex;
  justify-content: center;
}

.cfa-page .titre1 {
  font-size: 55px;
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.cfa .container-titre {
  margin-top: -58px;
}

.cfa .titre2 {
  font-size: 32px;
  background: #000;
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.cfa .titre3 {
  font-size: 24px;
  background: #000;
  color: #fff;
  padding: 20px 20px;
  text-transform: uppercase;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.tableau .row:nth-child(odd) {
  background-color: #f5f5f5 !important;
}

/* ######################################### */
/* ORGANISATEURS : SOUTIENS */
/* ######################################### */
.soutiens-page {
  padding: 165px 0;
}

.soutiens {
  padding: 0 0 70px 0;
}

.soutiens-card .card {
  border: none !important;
}

.cfa .container-titre {
  display: flex;
  justify-content: center;
}

.soutiens-page .titre1 {
  font-size: 55px;
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.soutiens .container-titre {
  margin-top: -58px;
}

.soutiens .titre2 {
  font-size: 32px;
  background: #000;
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.soutiens .titre3 {
  font-size: 24px;
  background: #000;
  color: #fff;
  padding: 20px 20px;
  text-transform: uppercase;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

/* ######################################### */
/* PARTENAIRES */
/* ######################################### */
.exclusif-page {
  padding: 165px 0;
}

/* .exclusif {
  padding: 0 0 70px 0;
} */

.exclusif-page .titre1 {
  font-size: 55px;
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.exclusif .container-titre {
  margin-top: -28px;
}

.exclusif .titre2 {
  font-size: 32px;
  background: #000;
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.exclusif-bg {
  background-color: #ebebeb;
  padding: 90px 35px 50px 35px;
  margin-top: -28px;
}

.exclusif-container {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 65px;
  padding: 40px 25px;
}

.exclusif-container .titre {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600;
  font-family: "Gotham Black", sans-serif;
  text-transform: uppercase;
  border: solid 1px #e84b0f;
  background: #e84b0f;
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  padding: 10px 40px 10px 40px;
  margin-top: -7px;
  text-align: center;
}

.exclusif-container .titre2 {
  font-size: 24px;
  background: #000;
  border: solid 1px #000;
  color: #fff;
  padding: 6px 15px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  margin-top: 35px;
  margin-bottom: 40px;
}

.majeurs-bg {
  background-color: #ebebeb;
  padding: 90px 35px 50px 35px;
  margin-top: -28px;
}

.majeurs-container {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 65px;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.majeurs-container .col-majeurs {
  font-size: 0.8rem;
  padding-right: 110px;
}

.exposants-page {
  padding: 165px 0;
}

.exposants-page .titre1 {
  font-size: 55px;
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.exposants .container-titre {
  margin-top: -28px;
}

.exposants .titre2 {
  font-size: 32px;
  background: #000;
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.exposants-bg {
  background-color: #ebebeb;
  padding: 110px 35px 120px 35px;
  margin-top: -28px;
}

/* ######################################### */
/* CONTACT */
/* ######################################### */
.contact-page {
  padding: 165px 0;
}

.contact-page .titre1 {
  font-size: 55px;
  background: linear-gradient(
    90deg,
    rgba(232, 75, 15, 1) 0%,
    rgba(240, 125, 0, 1) 100%
  );
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.contact .container-titre {
  margin-top: -28px;
}

.contact .titre2 {
  font-size: 32px;
  background: #000;
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Gotham Black", sans-serif;
  border-radius: 35px;
  z-index: 10;
  position: relative;
}

.contact-bg {
  background-color: #ebebeb;
  padding: 90px 35px 50px 35px;
  margin-top: -28px;
}

.contact-container {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 65px;
  padding: 40px 25px;
}

.button-contact {
  border: solid 1px #eb6009;
  background-color: #eb6009;
  border-radius: 25px;
  color: #fff;
  font-weight: bold;
  padding: 6px 20px 8px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.button-gmap {
  border: solid 1px #1795a1;
  background-color: #1795a1;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 6px 20px 8px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  /* text-transform: uppercase; */
  text-decoration: none;
}

.button-gmap:hover {
  border: solid 1px #eb6009;
  background-color: #1795a1;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 6px 20px 8px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  /* text-transform: uppercase; */
  text-decoration: none;
}

.prochaine-journee {
  font-size: 1.7rem;
}

.contact-adresse {
  font-size: 1.3rem;
}

/* ######################################### */
/* RESPONSIVE */
/* ######################################### */
@media screen and (max-width: 640px) {
  .header .link {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.95rem;
  }

  .header .link:hover {
    text-decoration: none;
    color: #1795a1;
    text-transform: uppercase;
    font-size: 0.95rem;
  }

  .carre-blanc {
    display: none;
  }

  .display-none {
    display: none;
  }

  /* HOME */
  .home .programme .c-programme {
    border: solid 10px #000;
    padding: 35px;
    background-color: #fff;
    width: 87%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 115px;
  }

  .home .programme .c-programme .programme-soustitre {
    font-size: 1rem;
    font-weight: 600;
    background-color: #e0e0e0;
    color: #000;
    padding: 10px 40px 10px 40px;
    /* width: 250px; */
    text-align: center;
    margin-top: -9px;
  }

  .home .c-programme .prog {
    font-size: 1.1rem;
    color: black;
    text-align: center;
  }

  .home .c-programme .prog p {
    padding-top: 20px;
  }

  .home .c-programme .prog .horaire {
    color: #1795a1;
    font-weight: 600;
  }

  .home .c-programme .prog .horaire-soustitre {
    font-size: 0.76rem;
  }

  .container-contact {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -50px;
  }

  .background-contact .programme-titre2 {
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
    background: rgb(232, 75, 15);
    background: linear-gradient(
      90deg,
      rgba(232, 75, 15, 1) 0%,
      rgba(240, 125, 0, 1) 100%
    );
    color: #fff;
    padding: 10px 40px 10px 40px;
    text-align: center;
    margin-top: -5px;
  }

  .background-contact .t-form {
    text-align: center;
    max-width: 1044px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    /* padding:0 22px; */
    margin-top: 25px;
    margin-bottom: 25px;
    box-sizing: border-box;
  }

  /* .video .col {
	  flex: none;
  } */

  .logo-entreprise {
    text-align: center;
  }

  /* Offre */
  .button-offre-postuler {
    border: solid 2px #1795a1;
    background-color: #1795a1;
    border-radius: 30px;
    color: #fff;
    font-weight: bold;
    padding: 6px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 70px;
  }

  .button-offre-postuler:hover {
    border: solid 2px #1795a1;
    background-color: #1795a1;
    border-radius: 30px;
    color: #eb6009;
    font-weight: bold;
    padding: 6px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 70px;
  }

  .offre-details-padding {
    padding: 10px 25px !important;
    font-size: 0.9rem;
  }

  /* ORGANISATEURS CFA */
  .cfa-page .titre1 {
    font-size: 28px;
    background: linear-gradient(
      90deg,
      rgba(232, 75, 15, 1) 0%,
      rgba(240, 125, 0, 1) 100%
    );
    color: #fff;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .cfa .container-titre {
    margin-top: -45px;
  }

  .cfa .titre2 {
    font-size: 20px;
    background: #000;
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .cfa .titre3 {
    font-size: 16px;
    background: #000;
    color: #fff;
    padding: 20px 20px;
    text-transform: uppercase;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .display-none {
    display: none;
  }

  /* ORGANISATEURS SOUTIENS */
  .soutiens-page .titre1 {
    font-size: 24px;
    background: linear-gradient(
      90deg,
      rgba(232, 75, 15, 1) 0%,
      rgba(240, 125, 0, 1) 100%
    );
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .soutiens .container-titre {
    margin-top: -47px;
  }

  .soutiens .titre2 {
    font-size: 22px;
    background: #000;
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .soutiens .titre3 {
    font-size: 24px;
    background: #000;
    color: #fff;
    padding: 20px 20px;
    text-transform: uppercase;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  /* Partenaires */
  .exclusif-page .titre1 {
    font-size: 25px;
    background: linear-gradient(
      90deg,
      rgba(232, 75, 15, 1) 0%,
      rgba(240, 125, 0, 1) 100%
    );
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .exclusif .container-titre {
    margin-top: -19px;
  }

  .exclusif .titre2 {
    font-size: 15px;
    background: #000;
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .majeurs-container {
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 65px;
    padding-top: 40px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .majeurs-container .col-majeurs {
    font-size: 0.7rem;
    text-align: center;
    padding-left: 25px;
    padding-right: 30px;
  }

  .majeurs-container .col-majeurs .text-end {
    text-align: center !important;
  }

  .exposants-page .titre1 {
    font-size: 24px;
    background: linear-gradient(
      90deg,
      rgba(232, 75, 15, 1) 0%,
      rgba(240, 125, 0, 1) 100%
    );
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .exposants .container-titre {
    margin-top: -18px;
  }

  .exposants .titre2 {
    font-size: 18px;
    background: #000;
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  /* Contact */
  .contact-page .titre1 {
    font-size: 30px;
    background: linear-gradient(
      90deg,
      rgba(232, 75, 15, 1) 0%,
      rgba(240, 125, 0, 1) 100%
    );
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .contact .container-titre {
    margin-top: -18px;
  }

  .contact .titre2 {
    font-size: 14px;
    background: #000;
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Gotham Black", sans-serif;
    border-radius: 35px;
    z-index: 10;
    position: relative;
  }

  .prochaine-journee {
    font-size: 1.3rem;
  }

  .contact-adresse {
    font-size: 1rem;
  }

  .button-contact {
    border: solid 1px #eb6009;
    background-color: #eb6009;
    border-radius: 25px;
    color: #fff;
    font-weight: bold;
    padding: 6px 20px 8px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 19px;;
  }
}
